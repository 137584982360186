<template>
  <div>
    <!-- FILTROS -->
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form class="mt-2">
          <!-- Filtros Simples -->
          <b-row>
            <b-col md="2">
              <b-form-group label="Pesquisar Por:" label-for="pesquisarPor">
                <v-select-pt
                  id="pesquisarPor"
                  :clearable="false"
                  v-model="pesquisarPor"
                  :options="tipoPesquisa"
                  label="name"
                  class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Descrição" label-for="pesquisar">
                <b-form-input
                  v-if="pesquisarPor.id == 1"
                  id="pesquisar"
                  type="text"
                  v-model="descricao"
                  placeholder="Nome"
                  class="form-control cleave-height"
                  :raw="false"
                />
                <cleave
                  v-if="pesquisarPor.id == 2"
                  id="pesquisar"
                  type="text"
                  v-model="descricao"
                  placeholder="Nº Contrato"
                  class="form-control cleave-height"
                  :raw="false"
                  :options="options.cleaveCodigo"
                />
                <cleave
                  v-if="pesquisarPor.id == 3"
                  id="pesquisar"
                  type="text"
                  v-model="descricao"
                  placeholder="CPF"
                  class="form-control cleave-height"
                  :raw="false"
                  :options="options.cleaveCPF"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data Inicial" label-for="dataInicial">
                <validation-provider #default="{ errors }" name="Data Inicial">
                  <b-input-group>
                    <cleave
                      id="dataInicial"
                      type="text"
                      v-model="dataInicial"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataInicialDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataInicial"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data Final" label-for="dataFinal">
                <validation-provider #default="{ errors }" name="Data Inicial">
                  <b-input-group>
                    <cleave
                      id="dataFinal"
                      type="text"
                      v-model="dataFinal"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                      :rules="{
                        dataPassada: {
                          value: dataInicial,
                          dataParametro: dataFinal,
                          stringParametro: 'data de inicial',
                        },
                      }"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataFinalDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataFinal"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Botões -->
          <b-row align-h="end">
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 21px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Prévia' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 21px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportToPDF()">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="exportToExcel()">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- TABELA -->
    <b-card v-if="this.itemsTabela.length > 0 || isBusy">
      <template v-if="isBusy">
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Tabela...</strong>
        </div>
      </template>
      <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
        <b-row>
          <b-col md="6">
            <p>
              Total de Contratos: <b-badge variant="primary"> {{ totalRows }}</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <p>
              Valor Total do IOF: <b-badge variant="primary"> {{ this.totalValoresContratos }}</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <!-- GRID -->
            <div ref="tabelaGrid" :itemsTabela="this.itemsTabela">
              <b-table
                style="overflow-x: auto; -webkit-overflow-scrolling: touch"
                outlined
                id="tabelaGrid"
                ref="tabelaGrid"
                striped
                :per-page="perPage"
                :current-page="currentPage"
                :fields="this.currentFields"
                :items="this.itemsTabela"
                responsive="sm"
                class="position-relative custom-rounded-table"
                :row-details="(row) => row.item.detailsShown"
              >
                <template #cell(detalhamento)="row">
                  <b-button size="sm" @click="row.toggleDetails" variant="primary" class="mr-2">
                    {{ row.detailsShowing ? 'Esconder' : 'Mostrar' }}
                  </b-button>
                </template>
                <template #row-details="row">
                  <b-row class="border-top mb-2 mx-3"></b-row>
                  <b-row class="mb-2 mx-3">
                    <b-table small :fields="aliquotaFields" :items="[row.item.detalhamento]"> </b-table>
                  </b-row>
                </template>
              </b-table>
              <b-card-body v-if="this.itemsTabela.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  style="align-items: center"
                  label="Por Página"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="this.itemsTabela.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <relatorioFinaceiroIOFPaisagem ref="exportarPDF" :parametro="parametroPDF" id="exportarPDF" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, dataFutura, dataPassada, valor } from '@validations'
  import { formatarData, formatarDataGlobal, formatarValorReais, formatarParaNumber } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import relatorioFinaceiroIOFPaisagem from './components/relatorioFinaceiroIOFPaisagem.vue'
  import { VueSelect } from 'vue-select'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import VueHtml2pdf from 'vue-html2pdf'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import * as XLSX from 'xlsx'

  export default {
    components: {
      valor,
      VueHtml2pdf,
      ToastificationContent,
      formatarData,
      formatarValorReais,
      generateAndDownloadPdf,
      formatarValorReais,
      relatorioFinaceiroIOFPaisagem,
      useJwt,
      dataPassada,
      formatarData,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      VueSelect,
      required,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        itemsTabela: [],
        totalValoresContratos: 0,
        valor: 0,
        isBusy: false,
        totalRows: 1,
        nomeRelatorio: 'Relatório de IOF',
        descricao: '',
        pesquisarPor: { id: 1, name: 'Nome' },
        parametroPDF: {
          dados: [],
          colunas: [],
        },
        linhasTabela: [],
        datePicker: {
          dataInicial: '',
          dataFinal: '',
        },
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        tipoPesquisa: [
          { id: 1, name: 'Nome' },
          { id: 2, name: 'Nº Contrato' },
          { id: 3, name: 'CPF' },
        ],
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        dataInicial: '',
        dataFinal: '',
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          cleaveCPF: {
            numericOnly: true,
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveCodigo: {
            numericOnly: true,
            delimiters: ['/'],
            blocks: [6, 2],
          },
        },
        number: {},
        colunasTabelas: [],
        currentFields: [
          {
            key: 'codigo',
            label: 'Nº Contrato',
            sortable: true,
          },
          {
            key: 'nome',
            label: 'Nome',
          },
          {
            key: 'cpf',
            label: 'CPF',
          },
          {
            key: 'dataNascimento',
            label: 'Data Nascimento',
          },
          {
            key: 'valorDiario',
            label: 'Valor Diário',
            sortable: true,
            formatter: (value) => formatarValorReais(value),
          },
          {
            key: 'valorEfetivo',
            label: 'Valor Efetivo',
            sortable: true,
            formatter: (value) => formatarValorReais(value),
          },
          {
            key: 'valorTotal',
            label: 'Valor Total',
            sortable: true,
            formatter: (value) => formatarValorReais(value),
          },
          {
            key: 'createdAt',
            label: 'Data Inicio',
            sortable: true,
          },
          {
            key: 'detalhamento',
            label: 'Detalhamento',
          },
        ],
        aliquotaFields: [
          { key: 'baseCalculo', label: 'Base de Cálculo' },
          { key: 'porcentagemDaria', label: 'Aliquota Diária (%)' },
          { key: 'diasContabilizados', label: 'Dias Contabilizados' },
          { key: 'porcentagemEfetivo', label: 'Aliquota Efetivo (%)' },
        ],
      }
    },
    mounted() {
      if (this.userData.role !== 'master') {
        this.institutoId = this.userData.institutoId
      } else {
        this.institutoId = this.userData.institutoSelecionado
      }
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        this.totalRows = 0
        this.currentPage = 1

        let filtro = {
          dataInicial: formatarDataGlobal(this.dataInicial) ?? null,
          dataFinal: formatarDataGlobal(this.dataFinal) ?? null,
          descricao: this.descricao ?? null,
          pesquisarPor: this.pesquisarPor.id,
          institutoId: this.institutoId,
        }

        useJwt
          .post('financeiro/GetRelatorioIOF', filtro)
          .then((response) => {
            if (!response.data.dados.length > 0) {
              this.$message.error('Não há dados para exportar!')
              this.itemsTabela.length = 0
              return
            }
            console.log(response.data.dados)
            this.itemsTabela = response.data.dados.map((item) => ({
              ...item,
              codigo: item.codigo,
              valorDiario: Number(item.valorDiarioIOF),
              valorEfetivo: Number(item.valorEfetivoIOF),
              valorTotal: Number(item.valorIOF),
              nome: item.segurado.tomador.nomeCompleto,
              cpf: item.segurado.tomador.cpf,
              dataNascimento: formatarData(item.segurado.tomador.dataNascimento),
              createdAt: formatarData(item.createdAt),
              detalhamento: {
                porcentagemDaria: item.taxaDiariaIOF ? `${parseFloat(item.taxaDiariaIOF).toFixed(2)}%` : '0%',
                diasContabilizados: item.quantidadeDiasIOF ?? 0,
                porcentagemEfetivo: item.taxaEfetivaIOF ? `${parseFloat(item.taxaEfetivaIOF).toFixed(2)}%` : '0%',
                baseCalculo: item.valorBaseCalculo,
              },
            }))

            this.itemsTabela = this.itemsTabela.sort((tomadorA, tomadorB) => tomadorA.nome.localeCompare(tomadorB.nome))

            this.totalRows = this.itemsTabela.length
            this.items = response.data.dados
            this.totalValoresContratos = response.data.dadosComTotal.pop()
            this.totalValoresContratos = formatarValorReais(this.totalValoresContratos.totalValoresContratos)
            this.linhasTabela = this.itemsTabela
            this.colunasTabelas = this.currentFields.map((item) => ({
              label: item.label,
              key: item.key,
            }))
            const colunasPDF = [
              { key: 'codigo', label: 'Nº Contrato', sortDesc: true },
              { key: 'cpf', label: 'CPF' },
              { key: 'nome', label: 'Nome' },
              { key: 'dataNascimento', label: 'Data Nascimento' },
              { key: 'baseCalculo', label: 'Base de Cálculo' },
              { key: 'diasContabilizados', label: 'Dias Contabilizados' },
              { key: 'porcentagemEfetivo', label: 'Aliquota Efetivo (%)' },
              { key: 'porcentagemDaria', label: 'Aliquota Diária (%)' },
              { key: 'valorEfetivo', label: 'Valor Efetivo' },
              { key: 'valorDiario', label: 'Valor Diário' },
              { key: 'valorTotal', label: 'Valor Total' },
              { key: 'createdAt', label: 'Data Inicio' },
            ]
            const itemsPDF = this.linhasTabela.map((item) => {
              return {
                ...item,
                ...item.detalhamento,
              }
            })
            // Monta variável global de relatório PDF
            this.parametroPDF = {
              dados: itemsPDF,
              colunas: colunasPDF.filter((item) => item.key !== 'detalhamento'),
              institutoNome: this.userData.Instituto.nome,
            }
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      exportToPDF() {
        if (!this.itemsTabela.length > 0) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }
        if (this.$refs.tabelaGrid) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2PdfLandscape.$el.innerHTML
          let relatorioPaisagem = true
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                      ${this.userData.Instituto.logradouro},
                      ${this.userData.Instituto.numero},
                      ${this.userData.Instituto.bairro},
                      ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                      E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                    `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            relatorioPaisagem,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportToExcel() {
        if (!this.itemsTabela.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        // Usando os dados e colunas para criar a planilha
        const ws = XLSX.utils.json_to_sheet(
          this.itemsTabela.map((item) => ({
            'Nº Contrato': item.codigo,
            CPF: item.cpf,
            Nome: item.nome,
            'Data Nascimento': item.dataNascimento,
            'Base de Cálculo': item.detalhamento.baseCalculo,
            'Dias Contabilizados': item.detalhamento.diasContabilizados,
            'Aliquota Efetivo (%)': item.detalhamento.porcentagemEfetivo,
            'Aliquota Diária (%)': item.detalhamento.porcentagemDaria,
            'Valor Efetivo': item.valorEfetivo,
            'Valor Diário': item.valorDiario,
            'Valor Total': item.valorTotal,
            'Data Inicio': item.createdAt,
          })),
        )

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
    },
    watch: {
      dataInicialDatePicker(newVal) {
        this.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>
<style>
  .background-details-table {
    background-color: #f3f2f7;
    color: black;
  }
  body.dark-layout .background-details-table {
    background-color: #343d55;
    color: white;
  }
</style>
